<template>
  <UiPopup
    :model-value="modelValue"
    hide-close-button
    title="Lead returned to Queue"
    :description="description"
    primary-button-text="Go to Queue"
    size="small"
    @confirm="confirm"
  >
  </UiPopup>
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  manual?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const description = computed(() => {
  return props.manual
    ? 'You move this lead back to Queue, go back and pick-up another lead '
    : 'You didn’t make a call within the timer, lead was returned to Queue'
})

const confirm = () => {
  emits('input')
  emits('update:modelValue', false)
}
</script>

<style scoped></style>
